// import wordpress from '../content/thumbnails/wp.png'
// import react from '../content/thumbnails/react.png'
// import vue from '../content/thumbnails/vue.png'

export default [
  // {
  //   title: 'Getting Started with Vue - Chicago JavaScript',
  //   path: 'https://www.youtube.com/watch?v=FPVI1wd7Uo0',
  //   img: vue,
  // },
  // {
  //   title: 'An Introduction to React - Chicago JavaScript',
  //   path: 'https://www.youtube.com/watch?v=35-WmqaQCj8&t',
  //   img: react,
  // },
  // {
  //   title: 'Developing a WordPress Theme from Scratch - WordCamp Chicago',
  //   path:
  //     'https://wordpress.tv/2017/08/30/tania-rascia-how-to-develop-a-wordpress-theme-from-scratch/',
  //   img: wordpress,
  // },
]
