export default [
  // {
  //   icon: '📝',
  //   title: 'TakeNote',
  //   path: 'https://takenote.dev',
  //   source: 'https://github.com/taniarascia/takenote',
  //   description: 'A web-based note-taking app with GitHub sync and Markdown support.',
  // },
  // {
  //   icon: '🌙',
  //   title: 'New Moon',
  //   path: 'https://taniarascia.github.io/new-moon/',
  //   source: 'https://github.com/taniarascia/new-moon/',
  //   description: 'The optimized dark theme for web development. Your new favorite theme.',
  // },
  // {
  //   icon: '🎮',
  //   title: 'Chip8.js',
  //   path: 'https://taniarascia.github.io/chip8/',
  //   source: 'https://github.com/taniarascia/chip8/',
  //   description: 'A Chip-8 emulator written in JavaScript for terminal and the browser.',
  // },
  // {
  //   icon: '🔱',
  //   title: 'MVC.js',
  //   path: 'https://taniarascia.github.io/mvc/',
  //   source: 'https://github.com/taniarascia/mvc/',
  //   description: 'A simple Todo MVC application in plain JavaScript.',
  // },
  // {
  //   icon: '🏺',
  //   title: 'Laconia',
  //   path: 'https://laconia.dev',
  //   source: 'https://github.com/taniarascia/laconia/',
  //   description: 'A modern PHP MVC framework written without libraries or frameworks.',
  // },
  // {
  //   icon: '⛏️',
  //   title: ' Primitive',
  //   path: 'https://taniarascia.github.io/primitive/',
  //   source: 'https://github.com/taniarascia/primitive/',
  //   description:
  //     'A minimalist front-end design toolkit built with Sass for developing responsive, browser-consistent web apps.',
  // },
  // {
  //   icon: '🐍',
  //   title: 'Snek.js',
  //   source: 'https://github.com/taniarascia/snek/',
  //   description: 'A terminal-based Snake implementation written in JavaScript (Node.js).',
  // },
  // {
  //   icon: '📦',
  //   title: 'Webpack Boilerplate',
  //   source: 'https://github.com/taniarascia/webpack-boilerplate',
  //   description:
  //     'A sensible Webpack 4 boilerplate with with Babel, Sass, ESLint, HMR, and prod/dev optimization.',
  // },
  // {
  //   icon: '💾',
  //   title: 'tania.dev',
  //   source: 'https://github.com/taniarascia/taniarascia.com/',
  //   description: 'Personal theme for Gatsby (the source of this website).',
  // },
]
