// import egghead from '../content/images/egghead.png'
// import hashnode from '../content/thumbnails/hashnode.svg'

export default [
  // {
  //   title: 'Switching Careers and Learning in Public - with Joel Hooks of Egghead.io',
  //   path: 'https://egghead.io/podcasts/switching-careers-and-learning-in-public-with-tania-rascia',
  //   img: egghead,
  // },
  // {
  //   title: 'Woman in Tech: Tania Rascia - with Bolaji Ayodeji of Hashnode',
  //   path: 'https://townhall.hashnode.com/women-in-tech-tania-rascia-ck2bfzn2100up3is10zhphol4',
  //   img: hashnode,
  // },
]
